<script lang="ts">
  import type { OptionT } from "@/types"
  import { builtins } from "@shared/lispable/functions.ts"
  import { createEventDispatcher } from "svelte"
  import SingleSelect from "../SingleSelect.svelte"

  export let value: string | undefined
  export let allowList: (keyof typeof builtins)[] | undefined = undefined
  export let fullwidth: boolean = false
  export let inline: boolean = false
  export let disabled: boolean = false

  const dispatch = createEventDispatcher<{
    change: string
  }>()
  const functionOptions: OptionT<string>[] = Object.entries(builtins)
    .map(([funcName, funcDef]) => ({ label: funcDef.name, value: funcName }))
    .filter(
      (item) =>
        !allowList || allowList.includes(item.value as keyof typeof builtins)
    )

  const changeFunc = (newName: string) => dispatch("change", newName)
</script>

<SingleSelect
  placeholder="Select"
  onChange={changeFunc}
  {value}
  options={functionOptions}
  {fullwidth}
  {inline}
  {disabled}
/>
