<script lang="ts">
  import { EnumT } from "@shared/schema"
  import Input from "./Input.svelte"
  import { Duration } from "luxon"
  import SingleSelect from "./SingleSelect.svelte"
  import { objectValues } from "@shared/util"
  import { createEventDispatcher } from "svelte"

  export let autofocus: boolean = false
  export let value: number | string | null | undefined
  export let minimumUnits: EnumT.DurationUnits
  export let fullwidth: boolean = false
  export let inputElem: HTMLInputElement | undefined = undefined
  export let wrapperElem: HTMLDivElement | undefined = undefined
  export let disabled: boolean

  const dispatch = createEventDispatcher<{ blur: undefined }>()

  let selectedUnits = minimumUnits

  $: resolvedValue =
    value && typeof value === "number"
      ? Duration.fromObject({ seconds: value }).as(selectedUnits)
      : 0

  const options = objectValues(EnumT.DurationUnits).map((value) => ({
    label: value,
    value,
  }))

  const onChangeInner = (newValue: number | null) =>
    (value = Duration.fromObject({ [selectedUnits]: newValue }).as("seconds"))

  const onUnitsChange = (newUnits: EnumT.DurationUnits) => {
    value = Duration.fromObject({ [newUnits]: resolvedValue }).as("seconds")
    selectedUnits = newUnits
  }

  const focusOut = (focusEvent: FocusEvent) => {
    const focused = focusEvent.relatedTarget
    if (!focused || !wrapperElem?.contains(focused as HTMLElement)) {
      dispatch("blur")
    }
  }
</script>

<div class="wrapper">
  <div class="wrapper-inner" bind:this={wrapperElem} on:focusout={focusOut}>
    <Input
      type="number"
      {autofocus}
      inline
      value={resolvedValue}
      on:keydown
      onChange={onChangeInner}
      {fullwidth}
      bind:elem={inputElem}
      {disabled}
      ><SingleSelect
        slot="right"
        inline
        value={selectedUnits}
        noborder
        {options}
        {disabled}
        onChange={onUnitsChange}
      /></Input
    >
  </div>
</div>

<style>
  .wrapper {
    display: inline-block;
    white-space: nowrap;
  }
  .wrapper-inner {
    display: flex;
    flex-direction: row;
    flex-flow: row;
  }
</style>
