import { assertDefined } from "@shared/util/index.ts"
import { Loader } from "@googlemaps/js-api-loader"
import type { LatLonObj } from "@shared/util/location"
import type { Optional } from "@shared/types.ts"
const DEFAULT_LOCATION = { lat: 45, lon: -74 }

export const getPreferredStyle = (darkMode: boolean) => {
  if (darkMode) {
    return `https://api.maptiler.com/maps/streets-v2-dark/style.json?key=${
      import.meta.env.VITE_APP_MAPTILER_API_KEY as string
    }`
  }
  return `https://api.maptiler.com/maps/streets-v2/style.json?key=${
    import.meta.env.VITE_APP_MAPTILER_API_KEY as string
  }`
}

export const getCenter = (positions: LatLonObj[]) => {
  const coords = positions.reduce(
    ({ lat: pLat, lon: pLon }, { lat: nLat, lon: nLon }) => ({
      lat: pLat + nLat,
      lon: pLon + nLon,
    }),
    { lat: 0, lon: 0 }
  )
  return positions.length > 0
    ? {
        lat: coords.lat / positions.length,
        lon: coords.lon / positions.length,
      }
    : DEFAULT_LOCATION
}

const loader = new Loader({
  apiKey: assertDefined(
    import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY as string | undefined
  ),
  version: "weekly",
  libraries: ["places"],
})

let placesApi: Optional<google.maps.PlacesLibrary> = null
const useMapPlacesApi = async () => {
  if (placesApi) {
    return placesApi
  }
  placesApi = await loader.importLibrary("places")

  return placesApi
}

let geocodingApi: Optional<google.maps.GeocodingLibrary> = null
const useMapGeocodingApi = async () => {
  if (geocodingApi) {
    return geocodingApi
  }
  geocodingApi = await loader.importLibrary("geocoding")

  return geocodingApi
}

let autoCompleteService: Optional<google.maps.places.AutocompleteService> = null
export const useAutocompletePlacesService = async () => {
  if (autoCompleteService) {
    return autoCompleteService
  }
  const { AutocompleteService } = await useMapPlacesApi()
  autoCompleteService = new AutocompleteService()
  return autoCompleteService
}

let geocoderService: Optional<google.maps.Geocoder> = null
export const useGeocoderService = async () => {
  if (geocoderService) {
    return geocoderService
  }
  const { Geocoder } = await useMapGeocodingApi()
  geocoderService = new Geocoder()
  return geocoderService
}
