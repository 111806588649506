<script lang="ts" context="module">
  const getDefaultArgItemForFunction = (
    funcName: keyof typeof builtins,
    baseColDef: ColumnDefinition | undefined
  ): ParsedItem | undefined => {
    let emptyValue: GenericDataValue | undefined = undefined
    if (baseColDef == null) {
      return undefined
    }
    switch (baseColDef.dataType) {
      case EnumT.DataType.text:
        emptyValue = ""
        break
      case EnumT.DataType.number:
        emptyValue = 0
        break
      case EnumT.DataType.boolean:
        emptyValue = false
        break
    }
    const parsedItemType = DATA_TYPE_TO_PARSED_ITEM_TYPE[baseColDef.dataType]
    if (!parsedItemType) {
      return undefined
    }
    assertValueMatchesLiteralType(emptyValue, parsedItemType)
    return { type: parsedItemType, value: emptyValue } as ParsedItem
  }
</script>

<script lang="ts">
  import { serialize, tryToParse } from "@shared/lispable/script"
  import { eed } from "@/util"
  import {
    ParsedItemType,
    type ParsedItem,
    assertParsedItemType,
    assertValueMatchesLiteralType,
  } from "@shared/lispable/parsing"
  import type {
    ColumnDefinition,
    FunctionArgument,
    GenericDataValue,
    ProcessedSheetContent,
  } from "@shared/types"
  import SingleSelect from "./SingleSelect.svelte"
  import FunctionSelect from "./generatorSpec/FunctionSelect.svelte"
  import type { OptionT } from "@/types"
  import { ReferenceNamespace } from "@shared/schema/enum"
  import { builtins } from "@shared/lispable/functions"
  import { assertDefined, stringifyJSONKey } from "@shared/util"
  import Button from "./Button.svelte"
  import { faTrash } from "@fortawesome/free-solid-svg-icons"
  import FilterArgumentControl from "./FilterArgumentControl.svelte"
  import { EnumT } from "@shared/schema"
  import { DATA_TYPE_TO_PARSED_ITEM_TYPE } from "@shared/constants"

  export let value: string
  export let sheetContent: ProcessedSheetContent
  export let onChange: (spec: string) => void
  export let defaultFilteringColumnId: string | undefined = undefined
  export let clearAfterChange: boolean = false
  export let disabled: boolean

  $: parsedSpec = value ? tryToParse(value) : undefined
  let selectedColumn: string | undefined = defaultFilteringColumnId
  let selectedFunctionName: string | undefined = undefined
  let funcArg: FunctionArgument | undefined = undefined
  let argItem: ParsedItem | undefined = undefined

  $: columnOptions = sheetContent.columns.map((column) => ({
    label: column.name,
    value: column.id,
  })) as OptionT<string>[]
  $: baseCol = sheetContent.columns.find((col) => col.id === selectedColumn)
  $: baseColDef = sheetContent.columnDefinitions.find(
    (colDef) => colDef.id === baseCol?.columnDefinitionId
  )

  const selectBaseFunction = (funcName: string) => {
    selectedFunctionName = funcName
    const argItemLocal = getDefaultArgItemForFunction(
      funcName as keyof typeof builtins,
      baseColDef
    )
    if (argItemLocal != null) {
      onChangeArgItem(argItemLocal)
    }
  }

  const wipe = () => {
    selectedColumn = undefined
    selectedFunctionName = undefined
    argItem = undefined
  }

  $: if (selectedFunctionName != null) {
    funcArg = builtins[selectedFunctionName as keyof typeof builtins]?.args[0]
  }

  const updateFromParsedSpec = (spec: ParsedItem) => {
    assertParsedItemType(spec, ParsedItemType.funcCall)
    const argCol = spec.args[0]
    if (stringifyJSONKey(argItem) !== stringifyJSONKey(spec.args[1])) {
      argItem = spec.args[1]
    }
    assertParsedItemType(argCol, ParsedItemType.reference)
    if (argCol.identifier !== selectedColumn) {
      selectedColumn = argCol.identifier
    }
    if (selectedFunctionName !== spec.funcName) {
      selectedFunctionName = spec.funcName
    }
  }

  $: if (parsedSpec) {
    updateFromParsedSpec(parsedSpec)
  }

  const onChangeArgItem = (parsedArgItem: ParsedItem) => {
    onChange(
      serialize({
        type: ParsedItemType.funcCall,
        funcName: assertDefined(selectedFunctionName),
        args: [
          {
            type: ParsedItemType.reference,
            namespace: ReferenceNamespace.columns,
            identifier: assertDefined(selectedColumn),
          },
          parsedArgItem,
        ],
      })
    )
    if (clearAfterChange) {
      selectedColumn = defaultFilteringColumnId
      selectedFunctionName = undefined
      funcArg = undefined
      argItem = undefined
    }
  }
</script>

<div class="wrapper">
  <div class="clear">
    {#if $$slots.clearButton}
      <slot name="clearButton" />
    {:else if selectedColumn || selectedFunctionName || argItem}
      <Button {disabled} iconLeft={faTrash} variant="danger" on:click={wipe} />
    {/if}
  </div>
  <div class="main-col">
    <SingleSelect
      bind:value={selectedColumn}
      options={columnOptions}
      placeholder="Filter on"
      {disabled}
    />
    <div class="comparator">
      <FunctionSelect
        value={selectedFunctionName}
        on:change={eed(selectBaseFunction)}
        allowList={["equals"]}
        fullwidth={false}
        inline
        {disabled}
      />
    </div>
    {#if selectedColumn && selectedFunctionName}
      <FilterArgumentControl
        onChange={onChangeArgItem}
        parsedItem={argItem}
        {sheetContent}
        baseColumnId={selectedColumn}
        {disabled}
      />
    {/if}
  </div>
</div>

<style>
  .main-col {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .comparator {
    align-self: center;
  }
  .wrapper {
    border: 2px solid var(--primary-accent);
    padding: 8px;
    border-radius: var(--default-rounding);
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .clear {
    align-self: flex-end;
  }
</style>
