<script lang="ts">
  import { Location, newTypedArray } from "@shared/util"
  import PreviewMap, { DisplayMode, type Waypoint } from "./PreviewMap.svelte"
  import MapItemPopup from "./MapItemPopup.svelte"
  import type {
    CommuteDetails,
    LocationDetails,
    NearbyPlaces,
    ProcessedSheetContent,
  } from "@shared/types"
  import { buildFieldValueLookup, getParsedValueByField } from "@shared/sheet"
  import { EnumT } from "@shared/schema"
  import MapGooglePopup from "./MapGooglePopup.svelte"

  export let sheetContent: ProcessedSheetContent
  export let rowId: string
  export let commuteDetails: CommuteDetails | undefined
  export let nearbyPlaces: NearbyPlaces | undefined
  export let onSelectPlace: (place: LocationDetails) => void

  $: sheetId = sheetContent.sheet.id
  $: sourceTitle = getParsedValueByField({
    sheetContent,
    rowId,
    field: EnumT.Field.title,
  })
  $: sourceLocationData = getParsedValueByField({
    sheetContent,
    rowId,
    field: EnumT.Field.location,
  })
  $: sourceLocation = sourceLocationData
    ? new Location(sourceLocationData.data)
    : undefined
  $: destinationLocation = commuteDetails
    ? new Location(commuteDetails.destination)
    : undefined

  $: waypoints = ((): Waypoint[] => {
    if (!sourceLocation || !destinationLocation) {
      return []
    }
    const waypoints = newTypedArray<Waypoint>()
    waypoints.push({
      location: sourceLocation,
      title: sourceTitle ?? undefined,
      displayMode: DisplayMode.markerPrimary,
      onClick: function (_event, context) {
        context.setPopup(MapItemPopup, {
          rowId,
          sheetId,
          fieldValueLookup: buildFieldValueLookup({
            sheetContent,
            rowId,
          }),
        })
      },
    })
    const destinationTitle = commuteDetails?.destination.title
    waypoints.push({
      location: destinationLocation,
      title: destinationTitle,
      displayMode: DisplayMode.markerSecondary,
      onClick: function (_event, context) {
        context.setPopup(MapGooglePopup, {
          title: destinationTitle,
          location: destinationLocation,
        })
      },
    })
    if (nearbyPlaces) {
      nearbyPlaces.places.forEach((place) => {
        const location = new Location(place)
        if (!destinationLocation || !location.equals(destinationLocation)) {
          waypoints.push({
            location,
            title: place.title,
            displayMode: DisplayMode.dotSecondary,
            onClick: function (_event, context) {
              onSelectPlace(place)
              context.setPopup(MapGooglePopup, {
                title: place.title,
                location,
              })
            },
          })
        }
      })
    }
    return waypoints
  })()
  $: routes = commuteDetails ? [{ route: commuteDetails.route }] : []
</script>

<PreviewMap {waypoints} {routes} />
