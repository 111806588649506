<script context="module" lang="ts">
  export type InputDataType = "text" | "number"
</script>

<script lang="ts">
  import { ArrowKey } from "@/constants"

  import type { OptionT, Size, Variant } from "@/types"
  import { classNames } from "@/util"

  import { safeParseFloat, EMPTY_ARRAY } from "@shared/util/index.ts"
  import { createEventDispatcher, tick } from "svelte"
  import SuggestionsWrapper from "./SuggestionsWrapper.svelte"

  type OT = $$Generic
  export let type: InputDataType
  type DT = $$Generic<typeof type extends "text" ? string : number | "">
  type T = DT | null
  export let value: T | undefined
  export let options: OptionT<OT>[] | undefined = undefined
  export let variant: Variant = "default"
  export let variants: Variant[] = []
  export let id: string | undefined = undefined
  export let autofocus: boolean = false
  export let elem: HTMLInputElement | undefined = undefined
  export let fullwidth: boolean = false
  export let fullheight: boolean = false
  export let placeholder: string | undefined = undefined
  export let disabled: boolean = false
  export let readonly: boolean = false
  export let plaincursor: boolean = false
  export let inline: boolean = false
  export let onChange: ((updatedValue: T) => void) | undefined = undefined
  export let size: Size = "default"

  const dispatch = createEventDispatcher<{
    selectOption: OT
    blur: FocusEvent
    // keydown: KeyboardEvent
  }>()

  let closeWrapper = false
  let isFocused = autofocus
  let simulateWrapperKey: ((k: ArrowKey) => void) | undefined = undefined

  $: computedVariantClasses = [...variants, variant].map((v) => `variant-${v}`)
  $: computedSizeClasses = `size-${size}`

  const numericKeys = new Set([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    "-",
  ])

  const checkNumeric = (ev: KeyboardEvent) => {
    if (
      (ev.key === "-" && value?.toString().length !== 1) ||
      (ev.key === "." && value?.toString().includes(".")) ||
      !numericKeys.has(ev.key)
    ) {
      ev.preventDefault()
    }
  }

  const stringValueInput = (ev: Event) => {
    const inputValue = (ev.target as HTMLInputElement).value as T
    if (onChange) {
      onChange(inputValue)
    } else {
      value = inputValue
    }
  }

  const numericValueInput = (ev: Event) => {
    const inputValue = safeParseFloat(
      (ev.target as HTMLInputElement).value
    ) as T | null
    if (inputValue === null) {
      ev.preventDefault()
      value = undefined
    } else {
      if (onChange) {
        onChange(inputValue)
      } else {
        value = inputValue
      }
    }
  }

  const selectOption = async (ev: CustomEvent<OT>) => {
    dispatch("selectOption", ev.detail)
    await tick()
    closeWrapper = true
    elem?.focus()
  }

  // TODO: Incomplete
  const checkNumericPaste = (ev: ClipboardEvent) => {
    ev.preventDefault()
    const val = safeParseFloat(ev.clipboardData?.getData("text") ?? "") ?? ""
  }

  const setFocused = (val: boolean) => () => {
    isFocused = val
  }

  const onBlur = (ev: FocusEvent) => {
    isFocused = false
    dispatch("blur", ev)
  }

  $: if (options) {
    closeWrapper = false
  }

  const keydownWrapper = (kev: KeyboardEvent) => {
    if (kev.key === ArrowKey.up || kev.key === ArrowKey.down) {
      simulateWrapperKey?.(kev.key)
    }
    //dispatch("keydown", kev)
  }

  $: expanded = options != null && options.length > 0 && !closeWrapper
</script>

<span on:focusin={setFocused(true)} on:focusout={setFocused(false)}>
  <SuggestionsWrapper
    {expanded}
    {fullwidth}
    {fullheight}
    options={options ?? EMPTY_ARRAY}
    {inline}
    bind:simulateKey={simulateWrapperKey}
    on:selectOption={selectOption}
  >
    <div
      class:inline
      class:expanded
      class={classNames(computedVariantClasses, "wrapper")}
    >
      {#if type === "text"}
        <input
          bind:this={elem}
          {id}
          {autofocus}
          {placeholder}
          {disabled}
          {readonly}
          value={value ?? ""}
          on:blur
          on:keydown
          on:click
          on:mouseup
          on:focus={setFocused(true)}
          class={classNames(computedVariantClasses, computedSizeClasses, {
            expanded,
            inline,
          })}
          class:plaincursor
          on:input={stringValueInput}
          type="text"
        />
      {:else if type === "number"}
        <input
          bind:this={elem}
          value={value ?? ""}
          {id}
          {autofocus}
          {placeholder}
          {disabled}
          {readonly}
          class={classNames(computedVariantClasses, computedSizeClasses, {
            expanded,
            inline,
          })}
          class:fullwidth
          on:click
          on:mouseup
          on:input={numericValueInput}
          on:keypress={checkNumeric}
          on:paste={checkNumericPaste}
          on:blur
          on:focus={setFocused(true)}
          on:keydown
          class:plaincursor
          type="text"
          inputmode="numeric"
          pattern="[0-9]*"
        />
      {/if}
      {#if $$slots.right}
        <div class="right">
          <slot name="right" />
        </div>
      {/if}
      {#if $$slots.left}
        <div class="left">
          <slot name="left" />
        </div>
      {/if}
    </div>
  </SuggestionsWrapper>
</span>

<style>
  .wrapper {
    position: relative;
    display: flex;
  }
  .wrapper:not(.variant-naked) {
    font-size: 15px;
    flex: 1;
  }
  .wrapper.variant-default {
    background-color: var(--secondary-bg);
    color: var(--secondary-fg);
    border: 2px solid var(--primary-accent);
    border-radius: 8px;
  }
  .wrapper.variant-default > input {
    color: var(--secondary-fg);
  }
  .wrapper.variant-default:focus-within {
    outline: none;
    border: 2px solid var(--action-alt);
  }
  .plaincursor {
    cursor: default;
  }
  .wrapper.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-color: var(--action-alt);
    border-left-color: var(--action-alt);
    border-right-color: var(--action-alt);
    border-bottom-color: rgba(0, 0, 0, 0);
  }
  .right {
    display: inline-block;
  }
  .wrapper.inline {
    display: inline-block;
  }
  input:not(.variant-naked) {
    flex: 1;
    /*padding-left: 4px;*/
    padding: 4px;
    border: none;
    outline: none;
  }
  input {
    background: none;
    outline: none;
  }
  input.size-large {
    font-size: 120%;
    padding: 8px;
  }
</style>
