<script lang="ts" context="module">
  const SUGGESTABLE_DATA_TYPES = new Set<EnumT.DataType>([
    EnumT.DataType.text,
    EnumT.DataType.number,
    EnumT.DataType.boolean,
  ])

  const COMPARABLE_FORMAT_SPEC_LOOKUP: PartialRecord<
    EnumT.FormatSpecType,
    EnumT.FormatSpecType[]
  > = {
    [EnumT.FormatSpecType.source]: [],
    [EnumT.FormatSpecType.currency]: [EnumT.FormatSpecType.currency],
    [EnumT.FormatSpecType.phone]: [EnumT.FormatSpecType.phone],
    [EnumT.FormatSpecType.datetime]: [EnumT.FormatSpecType.datetime],
    [EnumT.FormatSpecType.duration]: [EnumT.FormatSpecType.duration],
  }

  const ALLOW_EXACT_OPTION_FORMAT_SPECS = new Set<EnumT.FormatSpecType>([
    EnumT.FormatSpecType.source,
  ])

  const compareValues = (
    v1: GenericDataValue | undefined,
    v2: GenericDataValue | undefined,
    allowExact: boolean
  ) => {
    const tv1 = v1?.toString().trim().toUpperCase() ?? "undefined"
    const tv2 = v2?.toString().trim().toUpperCase() ?? ""
    return tv1.includes(tv2) && (allowExact || tv1 !== tv2)
  }

  const getFilteredOptions = (
    baseColumnId: string,
    sheetContent: ProcessedSheetContent,
    value: GenericDataValue | undefined
  ): OptionT<FilterArgumentSuggestionT>[] => {
    const colDef = getColumnDefinitionForCol({
      sheetContent,
      colId: baseColumnId,
    })

    if (!SUGGESTABLE_DATA_TYPES.has(colDef.dataType)) {
      return []
    }

    const comparableFormatSpecs =
      COMPARABLE_FORMAT_SPEC_LOOKUP[colDef.formatSpec.type]

    const allowExact = ALLOW_EXACT_OPTION_FORMAT_SPECS.has(
      colDef.formatSpec.type
    )

    const comparableColumns = sheetContent.columns
      .filter((col) => {
        const comparableColDef = getColumnDefinitionForCol({
          sheetContent,
          colId: col.id,
        })
        return (
          comparableColDef.dataType === colDef.dataType &&
          (comparableFormatSpecs == null ||
            comparableFormatSpecs.includes(comparableColDef.formatSpec.type)) &&
          col.id !== baseColumnId
        )
      })
      .filter((col) => compareValues(col.name, value, allowExact))

    const allRowIds = sheetContent.rows.map(({ id }) => id)
    //.filter((id) => !sheetContent.filteredRowIds.has(id))

    const valuesInColumn = [
      ...new Set(
        allRowIds
          .map((rowId) =>
            getParsedValueByColumn({
              sheetContent,
              cellKey: { colId: baseColumnId, rowId },
            })
          )
          .filter(
            (val) => val != null && compareValues(val, value, allowExact)
          ) as GenericDataValue[]
      ),
    ]

    return [
      ...comparableColumns.map(
        (col): OptionT<FilterArgumentSuggestionT> => ({
          label: FilterArgumentSuggestion,
          value: {
            type: "column",
            columnId: col.id,
            name: col.name,
            field:
              getColumnDefinitionForCol({ sheetContent, colId: col.id })
                .field ?? undefined,
          },
        })
      ),
      ...valuesInColumn.map(
        (val): OptionT<FilterArgumentSuggestionT> => ({
          label: FilterArgumentSuggestion,
          value: {
            type: "literal",
            value: val,
            dataType: colDef.dataType,
            formatSpec: colDef.formatSpec,
          },
        })
      ),
    ]
  }
</script>

<script lang="ts">
  import type { GenericDataValue, ProcessedSheetContent } from "@shared/types"
  import { DATA_TYPE_TO_PARSED_ITEM_TYPE } from "@shared/constants/index"
  import {
    getColumnDefinitionForCol,
    getParsedValueByColumn,
  } from "@shared/sheet"
  import type { OptionT } from "@/types"
  import type { FilterArgumentSuggestionT } from "./FilterArgumentSuggestion.svelte"
  import FilterArgumentSuggestion from "./FilterArgumentSuggestion.svelte"
  import { EnumT } from "@shared/schema"
  import {
    ParsedItemType,
    type ParsedItem,
    isLiteralItem,
    assertValueMatchesLiteralType,
  } from "@shared/lispable/parsing"
  import DataFormatEditor from "./DataFormat/display/DataFormatEditor.svelte"
  import { assertDefined, assertIsDefined, assertNever } from "@shared/util"
  import { ReferenceNamespace } from "@shared/schema/enum"
  import { DataFormatOverrideType } from "./DataFormat/display/types"
  import SelectedColumnCard from "./SelectedColumnCard.svelte"

  export let baseColumnId: string
  export let sheetContent: ProcessedSheetContent
  export let parsedItem: ParsedItem | undefined
  export let onChange: (updatedItem: ParsedItem) => void
  export let disabled: boolean

  let value: GenericDataValue | undefined = undefined

  $: options = getFilteredOptions(baseColumnId, sheetContent, value)
  $: baseCol = sheetContent.columns.find((col) => col.id === baseColumnId)
  $: baseColDef = sheetContent.columnDefinitions.find(
    (colDef) => colDef.id === baseCol?.columnDefinitionId
  )

  const onSelectOption = (
    optionValue: FilterArgumentSuggestionT | undefined
  ) => {
    if (!optionValue) {
      //onChange({ type: ParsedItemType.nullLiteral })
      value = undefined
      return
    }
    switch (optionValue.type) {
      case "column":
        onChange({
          type: ParsedItemType.reference,
          namespace: ReferenceNamespace.columns,
          identifier: optionValue.columnId,
        })
        break
      case "literal":
        const type = assertDefined(
          DATA_TYPE_TO_PARSED_ITEM_TYPE[optionValue.dataType]
        )
        value = optionValue.value
        switch (type) {
          case ParsedItemType.strLiteral:
            assertValueMatchesLiteralType(optionValue.value, type)
            onChange({ type: type, value: optionValue.value })
            break
          case ParsedItemType.numLiteral:
            assertValueMatchesLiteralType(optionValue.value, type)
            onChange({ type: type, value: optionValue.value })
            break
          case ParsedItemType.boolLiteral:
            assertValueMatchesLiteralType(optionValue.value, type)
            onChange({ type: type, value: optionValue.value })
            break
          default:
            assertNever(type)
        }
        break
      default:
        assertNever(optionValue)
    }
  }
  const setValue = (v: GenericDataValue | undefined) => (value = v)

  $: if (isLiteralItem(parsedItem)) {
    value = parsedItem.value
  }
  $: if (parsedItem?.type === ParsedItemType.nullLiteral) {
    setValue(undefined)
  }

  const onSetLiteral = (v: GenericDataValue | undefined) => {
    value = v
    if (v === undefined) {
      //onChange({ type: ParsedItemType.nullLiteral })
      return
    }
    assertIsDefined(baseColDef)
    const literalType = DATA_TYPE_TO_PARSED_ITEM_TYPE[baseColDef.dataType]
    switch (literalType) {
      case ParsedItemType.numLiteral:
        assertValueMatchesLiteralType(v, literalType)
        onChange({ type: ParsedItemType.numLiteral, value: v })
        break
      case ParsedItemType.strLiteral:
        assertValueMatchesLiteralType(v, literalType)
        onChange({ type: ParsedItemType.strLiteral, value: v })
        break
      case ParsedItemType.boolLiteral:
        assertValueMatchesLiteralType(v, literalType)
        onChange({ type: ParsedItemType.boolLiteral, value: v })
        break
      case undefined:
        throw `unexpected value for literalType ${literalType}`
      default:
        assertNever(literalType)
    }
  }

  $: dataFormatOverrides = {
    [DataFormatOverrideType.optionSettings]: {
      options,
      onSelectOption,
    },
  }

  $: referencedColumnId =
    parsedItem?.type === ParsedItemType.reference &&
    parsedItem.namespace === EnumT.ReferenceNamespace.columns
      ? parsedItem.identifier
      : undefined
</script>

{#if referencedColumnId}
  <SelectedColumnCard {sheetContent} colId={referencedColumnId} />
{:else if baseColDef}
  <DataFormatEditor
    {disabled}
    dataType={baseColDef.dataType}
    {value}
    onChange={onSetLiteral}
    formatSpec={baseColDef.formatSpec}
    {dataFormatOverrides}
    autofocus
  />
{/if}
