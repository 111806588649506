<script lang="ts">
  import type { OptionT, Size } from "@/types"
  import { debounce } from "@/util"
  import { useAutocompletePlacesService, useGeocoderService } from "@/util/map"
  import { Location } from "@shared/util/index.ts"
  import Input from "./Input.svelte"
  import LocationRow from "./LocationRow.svelte"

  export let value: Location | undefined = undefined
  export let size: Size = "default"

  let searchQuery: string | undefined | null = undefined
  let options: OptionT<{ placeId: string; types: string[]; label: string }>[] =
    []

  const getSuggestions = async () => {
    if (!searchQuery) {
      return
    }
    const autocompleteService = await useAutocompletePlacesService()
    const { predictions } = await autocompleteService.getPlacePredictions({
      input: searchQuery,
    })
    options = predictions.map((result) => ({
      value: {
        placeId: result.place_id,
        types: result.types,
        label: result.description,
      },
      label: LocationRow,
    }))
  }

  const debouncedGetSuggestions = debounce(getSuggestions)

  const selectOption = async (ev: CustomEvent<{ placeId: string }>) => {
    const { placeId } = ev.detail
    const geocoderService = await useGeocoderService()
    const { results } = await geocoderService.geocode({ placeId })
    const result = results[0]
    value = new Location({
      lat: result.geometry.location.lat(),
      lon: result.geometry.location.lng(),
    })
    options = []
    searchQuery = result.formatted_address
  }

  const onChange = (inputValue: string | null | undefined) => {
    searchQuery = inputValue
    if (searchQuery != null) {
      debouncedGetSuggestions()
    }
  }
</script>

<Input
  fullwidth
  type="text"
  placeholder="1600 Pennsylvania Avenue, Washington, DC"
  value={searchQuery ?? ""}
  {onChange}
  on:selectOption={selectOption}
  {options}
  {size}
/>
