<script lang="ts">
  import Button from "@/lib/Button.svelte"
  import DataFormatDisplay from "@/lib/DataFormat/display/DataFormatDisplay.svelte"
  import DataFormatEditor from "@/lib/DataFormat/display/DataFormatEditor.svelte"
  import {
    DataFormatOverrideType,
    type DataFormatOverrides,
  } from "@/lib/DataFormat/display/types"
  import MarkdownEditor from "@/lib/MarkdownEditor.svelte"
  import Choosable from "@/lib/controls/Choosable.svelte"
  import ListingTypeCard from "@/lib/modals/ChooseListingTypeModal/ListingTypeCard.svelte"
  import { EnumT } from "@shared/schema"
  import { CurrencyCode } from "@shared/schema/currencies"
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import { newTypedArray } from "@shared/util"

  const throwError = () => {
    throw new Error("expected test error")
  }

  let chosenListingType: EnumT.ListingType | null = null
  let markdownValue: string = ""

  const NULL_FORMAT_SPEC: FormatSpec = { type: EnumT.FormatSpecType.none }

  type DataTableTestRow<DT extends EnumT.DataType = EnumT.DataType> = {
    value: GenericDataValue<DT> | undefined
    dataType: DT
    formatSpec: FormatSpec
    dataFormatOverrides: DataFormatOverrides
  }

  const createTableRow = <DT extends EnumT.DataType>(
    dataType: DT,
    value: GenericDataValue<DT> | undefined,
    formatSpec: FormatSpec = NULL_FORMAT_SPEC,
    dataFormatOverrides: DataFormatOverrides = {}
  ): DataTableTestRow<DT> => ({
    dataType,
    value,
    formatSpec,
    dataFormatOverrides,
  })

  let dataTable = newTypedArray<DataTableTestRow>([
    createTableRow(EnumT.DataType.number, 17),
    createTableRow(EnumT.DataType.number, 17, {
      type: EnumT.FormatSpecType.currency,
      currencyCode: CurrencyCode.ZAR,
    }),
    createTableRow(EnumT.DataType.number, 17, {
      type: EnumT.FormatSpecType.currency,
      currencyCode: CurrencyCode.USD,
    }),
    createTableRow(EnumT.DataType.number, 1700000, {
      type: EnumT.FormatSpecType.duration,
    }),
    createTableRow(EnumT.DataType.number, 17, {
      type: EnumT.FormatSpecType.duration,
    }),
    createTableRow(EnumT.DataType.number, 17, {
      type: EnumT.FormatSpecType.duration,
      minimumUnits: EnumT.DurationUnits.hours,
    }),
    createTableRow(EnumT.DataType.number, 1700000, {
      type: EnumT.FormatSpecType.duration,
      minimumUnits: EnumT.DurationUnits.hours,
    }),
    createTableRow(EnumT.DataType.number, 1705875802, {
      type: EnumT.FormatSpecType.datetime,
      includeTime: false,
    }),
    createTableRow(EnumT.DataType.number, 1705875802, {
      type: EnumT.FormatSpecType.datetime,
      includeTime: true,
    }),
    createTableRow(EnumT.DataType.number, 0.623, {
      type: EnumT.FormatSpecType.rating,
    }),
    createTableRow(EnumT.DataType.number, undefined, {
      type: EnumT.FormatSpecType.rating,
    }),
    createTableRow(
      EnumT.DataType.text,
      "Beautiful two bedroom with a riverfront view and balcony"
    ),
    createTableRow(EnumT.DataType.text, undefined),
    createTableRow(EnumT.DataType.text, "airbnb", {
      type: EnumT.FormatSpecType.source,
    }),
    createTableRow(
      EnumT.DataType.text,
      "airbnb",
      {
        type: EnumT.FormatSpecType.source,
      },
      {
        [DataFormatOverrideType.sourceUrl]: "https://airbnb.com/",
      }
    ),
    createTableRow(EnumT.DataType.text, "airbnb"),
    createTableRow(EnumT.DataType.boolean, false, {
      type: EnumT.FormatSpecType.checkbox,
    }),
    createTableRow(EnumT.DataType.boolean, true, {
      type: EnumT.FormatSpecType.checkbox,
    }),
    createTableRow(EnumT.DataType.boolean, false),
    createTableRow(EnumT.DataType.boolean, true),
    createTableRow(EnumT.DataType.boolean, undefined, {
      type: EnumT.FormatSpecType.checkbox,
    }),
    createTableRow(EnumT.DataType.boolean, undefined),
    createTableRow(EnumT.DataType.textArray, undefined),
    createTableRow(
      EnumT.DataType.textArray,
      [
        "https://photos.zillowstatic.com/fp/214f9c1bf8a9de7fb2139f56c8e0bf40-se_medium_500_250.webp",
        "https://photos.zillowstatic.com/fp/400021ec6c8ffe67e5a4389dea11d062-se_medium_500_250.webp",
        "https://photos.zillowstatic.com/fp/033321d3dc879034f3ea691f8ecb9f34-se_medium_500_250.webp",
      ],
      { type: EnumT.FormatSpecType.images }
    ),
    createTableRow(
      EnumT.DataType.textArray,
      [
        "https://photos.zillowstatic.com/fp/214f9c1bf8a9de7fb2139f56c8e0bf40-se_medium_500_250.webp",
        "https://photos.zillowstatic.com/fp/400021ec6c8ffe67e5a4389dea11d062-se_medium_500_250.webp",
        "https://photos.zillowstatic.com/fp/033321d3dc879034f3ea691f8ecb9f34-se_medium_500_250.webp",
      ],
      { type: EnumT.FormatSpecType.images },
      { [DataFormatOverrideType.nofocus]: true }
    ),
    createTableRow(EnumT.DataType.textArray, [
      "https://photos.zillowstatic.com/fp/214f9c1bf8a9de7fb2139f56c8e0bf40-se_medium_500_250.webp",
      "https://photos.zillowstatic.com/fp/400021ec6c8ffe67e5a4389dea11d062-se_medium_500_250.webp",
      "https://photos.zillowstatic.com/fp/033321d3dc879034f3ea691f8ecb9f34-se_medium_500_250.webp",
    ]),
    createTableRow(EnumT.DataType.numberArray, [40.7638885, -74.0213591]),
    createTableRow(EnumT.DataType.json, { data: { x: "y", z: 23 } }),
  ])
</script>

<div>
  <div class="inner-content">
    <div>
      Running version <strong>{import.meta.env["VITE_APP_VERSION"]}</strong>
    </div>
    <Button variant="danger" on:click={throwError}>Trigger JS Error</Button>
    <div>
      <h2>Display Formats</h2>
      <table>
        <tr
          ><th>Data Type</th><th>Format Spec</th><th>Raw Value</th><th
            >Data Overrides</th
          ><th>Editor</th><th>Formatted Value</th></tr
        >
        {#each dataTable as row, i}
          <tr
            ><td><code>{row.dataType}</code></td><td
              ><code>{JSON.stringify(row.formatSpec)}</code></td
            ><td><code>{JSON.stringify(row.value)}</code></td><td
              ><code>{JSON.stringify(row.dataFormatOverrides)}</code></td
            ><td
              ><DataFormatEditor
                bind:value={dataTable[i].value}
                formatSpec={row.formatSpec}
                dataType={row.dataType}
                dataFormatOverrides={row.dataFormatOverrides}
              /></td
            ><td
              ><DataFormatDisplay
                value={row.value}
                dataType={row.dataType}
                formatSpec={row.formatSpec}
                dataFormatOverrides={row.dataFormatOverrides}
              /></td
            ></tr
          >
        {/each}
      </table>
    </div>
    <div>
      <Choosable
        chosen={chosenListingType === EnumT.ListingType.apartment}
        on:choose={() => (chosenListingType = EnumT.ListingType.apartment)}
        groupName="listingType"
        ariaDescription=""
        anyChosen={!!chosenListingType}
        clearable
        on:unchoose={() => (chosenListingType = null)}
      >
        <ListingTypeCard listingType={EnumT.ListingType.apartment} />
      </Choosable>
      <Choosable
        chosen={chosenListingType === EnumT.ListingType.automobile}
        on:choose={() => (chosenListingType = EnumT.ListingType.automobile)}
        groupName="listingType"
        ariaDescription=""
        anyChosen={!!chosenListingType}
        clearable
        hideCheck
        on:unchoose={() => (chosenListingType = null)}
      >
        <ListingTypeCard listingType={EnumT.ListingType.automobile} />
      </Choosable>
      <Choosable
        chosen={chosenListingType === EnumT.ListingType.hostel}
        on:choose={() => (chosenListingType = EnumT.ListingType.hostel)}
        groupName="listingType"
        ariaDescription=""
        anyChosen={!!chosenListingType}
        clearable
        on:unchoose={() => (chosenListingType = null)}
      >
        <ListingTypeCard listingType={EnumT.ListingType.hostel} />
      </Choosable>
      <Choosable
        chosen={chosenListingType === EnumT.ListingType.homestay}
        on:choose={() => (chosenListingType = EnumT.ListingType.homestay)}
        disabled
        groupName="listingType"
        ariaDescription=""
        anyChosen={!!chosenListingType}
        clearable
        on:unchoose={() => (chosenListingType = null)}
      >
        <ListingTypeCard listingType={EnumT.ListingType.homestay} />
      </Choosable>
    </div>
  </div>
  <div>
    <code>{markdownValue}</code>
    <MarkdownEditor bind:value={markdownValue} />
  </div>
</div>

<style>
  .inner-content {
    margin-left: 40px;
    margin-right: 40px;
    padding-top: 100px;
    padding-bottom: 500px;
  }
</style>
